<template>
  <transition name="pdf-fade">
    <div class="pdfmodal" :class="{isMobile}">
      <CRow>
        <CCol class="text-right">
          <CButton @click="onClose"  color="danger" class="mb-2">
            закрыть
          </CButton>
        </CCol>
      </CRow>
      <CForm @submit.prevent="saveData(service_request)">
      <CRow>
          <CCol sm="12">
            <CRow class="flex-fill">
              <h5>Заявка № {{service_request.name}} на {{service_request.date}} {{service_request.time}} *</h5>
              <br/>
             <table class="table table-striped small">
               <tr>
                 <td>
                   <h6>
                     Статус:
                   </h6>
                 </td>
                 <th>
                   <h6>
                    {{ service_request.status }}
                   </h6>
                 </th>
               </tr>
               <tr v-if="user_type==3">
                 <td>Город:</td>
                 <td>
                   <CSelect
                     v-model="service_request.city_name"
                     :options="cities.map(item => { return {option: item.id, value: item.name} })"></CSelect>
                 </td>
               </tr>
               <tr v-if="user_type==3">
                 <td>Поверитель:</td>
                 <td>
                   <CSelect
                     :value.sync="service_request.user_id"
                     :options="users"></CSelect>
                 </td>
               </tr>
              <tr>
                <td>Владелец (клиент)<sup>*</sup>:</td>
                <td>
                  <CInput
                    placeholder="владелец"
                    required="required"
                    v-model="service_request.miowner"/>
                </td>
              </tr>
              <tr>
                <td>Адрес поверки<sup>*</sup>:</td>
                <td>
                  <dadata-suggestions
                    class="form-control"
                    v-model="service_request.address"
                    :fullInfo.sync="data.queryFull"
                  />
                </td>
              </tr>
              <tr>
                <td>Телефон<sup>*</sup>:</td>
                <td>
                  <input
                    class="form-control"
                    v-mask="'# (###) ###-##-##'"
                    required="required"
                    v-model="service_request.phone"
                  />
                </td>
              </tr>
              <tr>
                <td>Дата визита:</td>
                <td>
                  <input
                    class="form-control"
                    v-mask="'####-##-##'"
                    placeholder="гггг-мм-дд"
                    required="required"
                    v-model="service_request.date"
                  />
                </td>
              </tr>
              <tr>
                <td>Время визита:</td>
                <td>
                  <CSelect
                    :value.sync="service_request.time"
                    :options="data.list_time"></CSelect>
                </td>
              </tr>
              <tr>
                <td>Кол-во счетчиков:</td>
                <td>
                  <CSelect
                    :value.sync="service_request.count_meters"
                    :options="[1,2,3,4,5,6]"></CSelect>
                </td>
              </tr>
              <tr>
                <td>Комментарий:</td>
                <td>
                  <CInput placeholder="" v-model="service_request.comment"></CInput>
                </td>
              </tr>
              <tr>
                <td>Примечание:</td>
                <td>
                  {{ service_request.note }}
                </td>
              </tr>
             </table>
              <table class="table">
              <tr>
                <th>
                  <CButton
                    @click="acceptData(service_request)"
                    v-if="data.show_accept"
                    color="warning">
                    назначить
                  </CButton>
                  <CButton
                    v-else
                    class="btn btn-secondary">
                    назначить
                  </CButton>
                </th>
                <th>
                  <CButton @click="approveData(service_request)"
                   v-if="data.show_approve"
                   class="btn btn-info">
                    согласовать
                  </CButton>
                  <CButton @click="onClose"
                           v-else
                           class="btn-secondary">
                  согласовать
                  </CButton>
                </th>
<!--                <td>-->
<!--                  <CButton-->
<!--                    type="submit"-->
<!--                    color="success"-->
<!--                    class="mb-3">-->
<!--                    сохранить-->
<!--                  </CButton>-->
<!--                </td>-->
              </tr>
              </table>
              * Внимание! Передача изменений всех параметров заявки в мобильное приложение производится только для заявок в статусе “Новая”. Во всех остальных статусах, в приложение может быть передано только изменение параметра “Поверитель”. В связи с этим не рекомендуется редактировать любые поля, кроме поля “Поверитель”, для всех заявок, не находящихся в статусе “Новая”.
            </CRow>
          </CCol>
        </CRow>
      </CForm>
    </div>

  </transition>
</template>
<script>

import {computed, reactive, watch, ref} from '@vue/composition-api';
import VueSuggestions from 'vue-suggestions';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default {
  name: "ServiceRequestModal",
  components: { VueSuggestions },
  props: {
    service_request: {
      type: Object,
      required: true
    },
    cities: {
      type: Array,
      required: true
    },
    contract: {
      type: Object,
      default: null
    },
    current_user: {
      type: Number,
      default: 0
    }
  },
  setup( props, ctx) {
    const store = ctx.root.$store;

    const data = reactive({
      city: {},
      query: '',
      queryFull: {},
      time: null,
      changeStatus: false,
      numPages: 4,
      currentPage: 0,
      pageCount: 0,
      type_show: false,
      list_time: ['', '08:00', '09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00'],
      show_accept: false,
      show_approve: false,
    });

    const isMobile = computed(() => {
      return window.innerWidth <= 760 ? true : false
    })

    const user_type = computed(() => {
      return store.getters.user_type;
    });

    const price = props.service_request.price/props.service_request.count_meters;

    const query = computed(() => {
        return ref('query').value
    });

    const users = computed(() => {
      let options = [
        {value: null, label: 'не указан'},
        {value: store.getters.user.id, label: store.getters.user.name},
      ];
      if (store.getters.user.slave_users) {
        store.getters.user.slave_users.forEach((item) => {
          if (item.slave)
            options.push({value: item.slave_id, label: item.slave.name})
        });
      }
      return options;
    });

    // const query = ref('service_request.address');

    data.show_accept = data.show_approve = false

    watch(() => props.current_user, (val, oldval) => {
      // console.log('val', val, oldval);
      if (val!==oldval && val) {
        data.show_accept = true;
        if (props.service_request.user_id && props.service_request.time
          && props.service_request.address && props.service_request.miowner
          && props.service_request.date)
          data.show_approve = true;
        else
          data.show_approve = false;
      }
      else
        data.show_accept = data.show_approve = false
    });

    watch(() => props.service_request.date, (val, oldval) => {
      // console.log('val', val, oldval);
      let date = new Date(val+' '+props.service_request.time+':00');
      if (val.length == 10 && date.getTime() < (new Date()).getTime()) {
        data.show_approve = false;
        Swal.fire('Внимание!', 'Дата не может быть прошедшей')
      }
      else {
        if (val !== oldval) {
          if (props.service_request.user_id && props.service_request.time
            && props.service_request.address && props.service_request.miowner
            && props.service_request.date)
            data.show_approve = true;
          else
            data.show_approve = false;
        }
      }
    })

    watch(() => props.service_request.city_name, (val, oldval) => {
      props.cities.forEach((item) => {
        if (item.name === val)
          props.service_request.city_id = item.id
      })
    })

      watch(() => props.service_request.count_meters, (val, oldval) => {
      // console.log('val', val, oldval);
      if (val !== oldval) {
        props.service_request.price = price * val;
      }
    })

    watch(() => props.service_request.time, (val, oldval) => {
      if (val !== oldval) {
        let date = new Date(props.service_request.date+' '+val+':00');
        if (date < new Date()) {
          data.show_approve = false;
          Swal.fire('Внимание!', 'Дата не может быть прошедшей')
        }
        else {
          if (props.service_request.user_id && props.service_request.time
            && props.service_request.address && props.service_request.miowner
            && props.service_request.date)
            data.show_approve = true;
          else
            data.show_approve = false;
        }
      }
    })

    watch(() => props.service_request.count_meters, (val, oldval) => {
      // props.service_request.price =
    })


    const onClose = () => {
      data.show_accept = data.show_approve = false;
      document.body.classList.remove('noscroll');
      ctx.emit('hide-document');
      data.changeStatus = false;
    };
    const acceptData = (item) => {
      item.city =
      ctx.emit('accept-service-request', item);
      onClose();
    };
    const approveData = (item) =>  {
      ctx.emit('approve-service-request', item);
      onClose();
    };
    const saveData = (item) => {
      ctx.emit('save-service-request', item);
      onClose();
    };
    const changePage = (step) =>
    {
      data.changeStatus = false;
      ctx.emit('change-document', step, service_request.current, service_request.documents)
    };
    const showMeters = () => {
      data.type_show = !data.type_show;
    };

    return {onClose, acceptData, approveData, saveData, showMeters, changePage,
      data, user_type, users, query, isMobile }

  },
}
</script>

<style scoped>

.table th, .table td {
  padding: 0.2rem 1.4rem
}

.noscroll{
  overflow: hidden !important;
}

.isMobile {
  width: 100vw !important;
}

.pdfmodal {
  position: fixed;
  z-index: 10000;
  background-color: #ffffff;
  top: 0;
  right: 0;
  height: 100vh;
  width: 30vw;
  padding: 30px 30px 30px 20px;
  -webkit-box-shadow: -4px 5px 8px 5px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: -4px 5px 8px 5px rgba(34, 60, 80, 0.2);
  box-shadow: -4px 5px 8px 5px rgba(34, 60, 80, 0.2);
  overflow-y: auto;
}
.pdfmodal .close {
  font-size: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 100;
}
.pdf-fade-enter-active, .pdf-fade-leave-active {
  transition: right 0.5s linear;
  right: 0;
}
.pdf-fade-enter, .pdf-fade-leave-to  {
  right: -100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: #606f7b;
}
img {
  max-height: 100vh;
}
label.form {
  color: white;
  font-family: Monaco, 'Courier New', Courier, monospace;
  font-weight: bold;
  margin-bottom: 2em;
  display: block;
}
input {
  padding: 0.45em;
  font-size: 1em;
}
.error {
  border: 1px solid red;
  background: pink;
  color: red;
  padding: 0.5em 3em;
  display: inline;
}
a.icon {
  cursor: pointer;
  display: block;
  border: 1px #333 solid;
  background: white;
  color: #333;
  font-weight: bold;
  padding: 0.25em;
  width: 1em;
  height: 1em;
  font-size: 1.5em;
}
.box-shadow {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
.overflow-hidden {
  overflow: hidden;
}
@media print {
  body {
    background-color: transparent;
  }
  #app {
    margin: 0;
    padding: 0;
  }
}
#meters-slider.carousel-control-prev, #meters-slider.carousel-control-next {
  color: #0b2e13;
}
</style>
