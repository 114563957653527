export default function isDispatcher ({ next, store }){
  // console.log('is middleware type user ', store.getters.user_type);

  if (store.getters.user_type == 1) {
    return next()
  }
  else {
    return next('Dashboard')
  }
}
